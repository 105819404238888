module.exports = [
  {
    name: '人际关系处理测试题',
    name2: 'Interpersonal relationship processing test questions',
    key: 'Personality_color_test',
    option: ['A', 'B'],
    description: '在你的现实生活中，你最不喜欢和什么样的人共事?本题考察人际交往的意识与技巧。由于价值观、知识面、经验等方面的不同，人与人之间相互之间喜欢或不喜欢都是正常的现象。',
    EnglishText: ``,
    background: `每个人都会说话，但说话有什么技巧？这个问题听起来很简单，但其实学问巨大。
  
      表达同样的意思，有些人的话听起来让人更舒服和更容易接受，另外一些人则相反。这些都和我们谈话和倾听的技巧有关。
      
      英国作家罗森对此进行了专门研究，希望从中总结出一些技巧，以便帮助人们改善交流技能、提升人际关系。
      
      拥有一流的谈话技巧无论在哪里都能派上用场。罗森和另外两位作者埃迪和彼得一起合写了一本新书叫The Talking Revolution（谈话革命）。`,
    questionList: [
      {
        question: '你平时是否关心自己的人缘? ',
        A: '是',
        B: '否',
      },
      {
        question: '在食堂里你一般都是独自吃饭吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '和一大群人在一起时，你是否会产生孤独感或失落感? ',
        A: '是',
        B: '否',
      },
      {
        question: '你是否经常不经同意就使用他人的东西? ',
        A: '是',
        B: '否',
      },
      {
        question: '当一件事没做好，你是否会埋怨合作者? ',
        A: '是',
        B: '否',
      },
      {
        question: '当你的朋友有困难时，你是否时常发现他们不打算来求助你? ',
        A: '是',
        B: '否',
      },
      {
        question: '假如朋友们跟你开玩笑过了头，你会不会板起面孔，甚至反目? ',
        A: '是',
        B: '否',
      },
      {
        question: '在公共场合，你有把鞋子脱掉的习惯吗?',
        A: '是',
        B: '否',
      },
      {
        question: '你认为在任何场合下都应该不隐瞒自己的观点吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '当你的同事、同学或朋友取得进步或成功时，你是否真心为他们高兴? ',
        A: '是',
        B: '否',
      },
      {
        question: '你喜欢拿别人开玩笑吗?  ',
        A: '是',
        B: '否',
      },
      {
        question: '和自己兴趣不同的人在一起相处时，也不会感到趣味索然、无话可可谈吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '当住在楼上时，你会往楼下倒水或丢纸屑吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '你经常指出别人的不足，要求他们去改进吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '当别人在融洽地交谈时，你会贸然地打断他们吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '你是否关心和常谈论别人的私事?? ',
        A: '是',
        B: '否',
      },
      {
        question: '你善于和老年人谈他们关心的问题吗?  ',
        A: '是',
        B: '否',
      },
      {
        question: '你讲话时常会说一些不文明的口头语吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '是否偶尔会做出一些言而无信的事?  ',
        A: '是',
        B: '否',
      },
      {
        question: '当有人在交谈或对你讲解一些事情时，你是否时常很难聚精会神地听下去?  ',
        A: '是',
        B: '否',
      },
      {
        question: '当你处于一个新集体中时，你觉得交新朋友是一件容易的事吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '你是一个愿意慷慨地招待同伴的人吗?  ',
        A: '是',
        B: '否',
      },
      {
        question: '你向别人吐露自己的抱负、挫折以及个人的种种事情吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '告诉别人一件事情时，你是否试图把事情的细节都交待得很清楚? ',
        A: '是',
        B: '否',
      },
      {
        question: '遇到不顺心的事，你会精神沮丧、意志消沉，或把气出在家人身上吗?',
        A: '是',
        B: '否',
      },
      {
        question: '你是否经常不经思索就随便发表意见?  ',
        A: '是',
        B: '否',
      },
      {
        question: '你是否注意赴约前不吃大葱、大蒜，以及防止身带酒气?',
        A: '是',
        B: '否',
      },
      {
        question: '你是否经常发牢骚? ',
        A: '是',
        B: '否',
      },
      {
        question: '在公共场合，你会随便喊别人的绰号吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '你关心报纸、电视等信息渠道中的社会新闻吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '当发觉自己无意中做错了事或损害了别人，是否会很快地承认错误并道歉?  ',
        A: '是',
        B: '否',
      },
      {
        question: '闲暇时，你是否喜欢跟人聊聊天?  ',
        A: '是',
        B: '否',
      },
      {
        question: '跟别人约会时，是否常让别人等你?   ',
        A: '是',
        B: '否',
      },
      {
        question: '你是否有时会与别人谈论一些自己感兴趣而他们不感兴趣的话题?   ',
        A: '是',
        B: '否',
      },
      {
        question: '你有逗乐儿童的小手法吗? ',
        A: '是',
        B: '否',
      },
      {
        question: '你平时告诫自己不要说虚情假意的话吗? ',
        A: '是',
        B: '否',
      },
    ],
  },
]
